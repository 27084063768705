import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { safeJsonFetch, safeFetch, FAILED } from "../utils";
import BadgeRenouvellementAbonnement from "../components/ui/BadgeRenouvellementAbonnement";
import BoutonRetour from "../components/ui/BoutonRetour";
import "./abonnement.css";
import LoadFailed from "../components/ui/LoadFailed";
import PrixHT from "../components/ui/PrixHT";
import { Temporal } from "@js-temporal/polyfill";
import useToken from "../hooks/useToken";

export async function abonnementLoader({ params }, token) {
    let url;
    if(params?.collectiviteId) {
        url = `${API_URL}/admin/collectivite/${params.collectiviteId}/abonnement/${params.abonnementId}`;
    } else if(params?.refExterne) {
        url =  `${API_URL}/admin/collectivite/ext_${params.refExterne}/abonnement/${params.abonnementId}`;
    } else {
        url = `${API_URL}/abonnement/${params.abonnementId}`;
    }

    const abonnement = await safeJsonFetch(url, token);
    return abonnement;
}

// Fonction appelée lors du clic sur la liste des fichiers liés à l'abonnement,
// chargée soit d'ouvrir le fichier dans un nouvel onglet, soit de le télécharger
async function getFichier(abonnement, fichier, setState, token, action, vueAdmin) {
    setState(oldState => ({
        ...oldState,
        fichiers: {
            ...oldState.fichiers,
            [fichier.id]: "loading", // pour afficher "chargement en cours"
        }
    }));

    let url = vueAdmin
        ? `${API_URL}/admin/file/${fichier.id}`
        : `${API_URL}/abonnement/${abonnement.id}/file/${fichier.id}`;

    let response = await safeFetch(url, token);

    if(response === FAILED) {
        setState(oldState => ({
            ...oldState,
            fichiers: {
                ...oldState.fichiers,
                [fichier.id]: "failed",  // pour afficher "chargement échoué"
            }
        }));
        return;
    }

    let fichierBlob = await response.blob();
    let fichierUrl = window.URL.createObjectURL(fichierBlob);

    if(action === "show") {
        window.open(fichierUrl, '_blank').focus();
    }

    // pour télécharger le fichier avec son nom initiale,
    // on est obligé de passer par un <a> et son attribut "download"
    if(action === "download") {
        var a = document.createElement("a");
        a.style = "display: none";
        a.href = fichierUrl;
        a.download = fichier.nom;
        a.click();
    }

    setState(oldState => ({
        ...oldState,
        fichiers: {
            ...oldState.fichiers,
            [fichier.id]: "default", // retour à l'affichage par déaut
        }
    }));

}

const LABELS = {
    ARTICLE: "Article",
    DESCRIPTION: "Description",
    PRIX_UNITAIRE: "Prix\u00A0unitaire",
    NOMBRE: "Nombre",
    TOTAL: "Total",
};

function Abonnement(props) {
    const abonnement = useLoaderData();

    return (
        <DetailAbonnement
            data={abonnement}
            nomCollectivite={abonnement.collectivite}
            vueAdmin={props.vueAdmin}
        ></DetailAbonnement>
    )
}

export function DetailAbonnement(props) {

    const abonnement = props.data;
    const { token } = useToken();
    const [state, setState] = useState({
        fichiers: abonnement !== FAILED
            ? Object.fromEntries(abonnement.fichiers.map( fichier => [fichier.id, "default"]))
            : []
    });

    return (
        <>
            <section className={"section-contenu-page page-abonnement" + (props.vueAdmin ? " page-abonnement-admin" : "")}>
                {
                    abonnement === FAILED
                        ? <LoadFailed/>
                        : <>
                            <div className="page-top-section-header">
                                {
                                    props.vueAdmin
                                        ? <span className="nom-collectivite">{props.nomCollectivite}</span>
                                        : null
                                }
                                <h1 className="titre-page">
                                    <div className="modele-abonnement">
                                        <FontAwesomeIcon
                                            icon={solid("pen-nib")}
                                            size="sm"
                                            className="icone-titre-page"
                                        />
                                        {abonnement.modele_abonnement}
                                        <div>
                                            <span className="code-abonnement">{abonnement.code}</span>
                                            <BadgeRenouvellementAbonnement abonnement={abonnement} />
                                        </div>
                                    </div>
                                </h1>
                            </div>
                            <span data-statut-abonnement={abonnement.statut} className="statut-abonnement">
                                <FontAwesomeIcon icon={solid("file-signature")} size="lg" />
                                {abonnement.statut}
                            </span>
                            <dl className={"timeline-container"} data-fin={abonnement.date_fin || null}>
                                <dt className="timeline-section-debut">Début</dt>
                                <dd className="timeline-section-debut">
                                    <time>
                                        {abonnement.date_debut
                                            ? new Date(abonnement.date_debut).toLocaleDateString()
                                            : ""}
                                    </time>
                                </dd>
                                {abonnement.date_fin ? (
                                    <>
                                        <dt className="timeline-section-reflexion">Temps de réflexion</dt>
                                        <dd className="timeline-section-reflexion">
                                            <time>
                                                {getTemporalDateDebutReflexion(abonnement).toLocaleString()}
                                            </time>
                                        </dd>
                                    </>
                                ) : <>
                                        <dt className="timeline-section-reflexion">Temps de réflexion</dt>
                                        <dd className="timeline-section-reflexion">
                                            <time>
                                                {getTemporalDateDebutReflexion(abonnement).toLocaleString()}
                                            </time>
                                        </dd>
                                        <dt className="timeline-section-limite-preavis">Limite de préavis</dt>
                                        <dd className="timeline-section-limite-preavis">
                                            <time>
                                                {getTemporalDateLimitePreavis(abonnement).toLocaleString()}
                                            </time>
                                        </dd>
                                        <dt className="timeline-section-tacite-reconduction">Tacite reconduction</dt>
                                        <dd className="timeline-section-tacite-reconduction">
                                            <time>
                                                {getDateReconduction(abonnement).toLocaleDateString()}
                                            </time>
                                        </dd>
                                    </>
                                }
                                <dt className="timeline-section-fin">Expiration</dt>
                                <dd className="timeline-section-fin">
                                    <time className="date-fin">
                                        {abonnement.date_fin
                                            ? new Date(abonnement.date_fin).toLocaleDateString()
                                            : ""}
                                    </time>
                                </dd>
                                <TimelineSvgCircle className="timeline-section-debut" />
                                {abonnement.date_fin
                                    ? (
                                        <>
                                            <TimelineSvgCircle className="timeline-section-reflexion" />
                                        </>
                                    )
                                    : (
                                        <>
                                            <TimelineSvgCircle className="timeline-section-reflexion" />
                                            <TimelineSvgCircle className="timeline-section-limite-preavis" />
                                            <TimelineSvgCircle className="timeline-section-tacite-reconduction" />
                                        </>
                                    )
                                }
                                <TimelineSvgCircle className="timeline-section-fin" />
                                <svg
                                    className="line horizontal"
                                    viewBox="0 0 10 4"
                                    preserveAspectRatio="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                    aria-label="représentation de la frise chronologique de l'abonnement "
                                >
                                    <line x1="0" y1="2" x2="10" y2="2" />
                                </svg>
                                <svg
                                    className="line vertical"
                                    viewBox="0 0 4 10"
                                    preserveAspectRatio="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <line x1="2" y1="0" x2="2" y2="10" />
                                </svg>
                            </dl>

                            <table className="details-abonnement table-buc">
                                <thead>
                                    <tr>
                                        <th>{LABELS.ARTICLE}</th>
                                        <th>{LABELS.DESCRIPTION}</th>
                                        <th>{LABELS.PRIX_UNITAIRE}</th>
                                        <th>{LABELS.NOMBRE}</th>
                                        <th>{LABELS.TOTAL}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {abonnement.articles.map((article, i) => (
                                        <tr key={i}>
                                            <td className="info-article">
                                                <span>{article.article}</span>
                                            </td>
                                            <td>
                                                <span className="small-label">{LABELS.DESCRIPTION}:&nbsp;</span>
                                                <span>{article.description}</span>
                                            </td>
                                            <td>
                                                <span className="small-label">
                                                    {LABELS.PRIX_UNITAIRE}:&nbsp;
                                                </span>
                                                <span><PrixHT prix={article.prix_unitaire}/></span>
                                            </td>
                                            <td>
                                                <span className="small-label">{LABELS.NOMBRE}:&nbsp;</span>
                                                <span>{article.quantite}</span>
                                            </td>
                                            <td>
                                                <span className="small-label">{LABELS.TOTAL}:&nbsp;</span>
                                                <span><PrixHT prix={article.sous_total}/></span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="5" className="abonnement-total-annuel">
                                            PAIEMENT TOTAL ANNUEL:&nbsp;
                                            <PrixHT prix={abonnement.prix_total_annuel}/>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                            <ul className="liste-documents">
                                {abonnement.fichiers.map((fichier) => {
                                    let content;
                                    if(state.fichiers[fichier.id] === "loading") {
                                        content = <>
                                                <span className="spinner-loader"></span>
                                                Chargement en cours&nbsp;: {fichier.nom}
                                            </>
                                    } else if(state.fichiers[fichier.id] === "failed") {
                                        content = <>Chargement échoué, veuillez réessayer ultérieurement&nbsp;: {fichier.nom}</>
                                    } else {
                                        content = <>
                                            <button
                                                className="fichier-download"
                                                aria-label={"Télécharger "  + fichier.nom}
                                                title={"Télécharger "  + fichier.nom}
                                                onClick={() => { getFichier(abonnement, fichier, setState, token, "download", props.vueAdmin) }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={solid("download")}
                                                    size="lg"
                                                    className="icone-titre-page"
                                                />
                                            </button>
                                            <button
                                                className="fichier-show"
                                                aria-label={"Afficher "  + fichier.nom}
                                                title={"Afficher "  + fichier.nom}
                                                onClick={(e) => getFichier(abonnement, fichier, setState, token, "show", props.vueAdmin)}>
                                                {fichier.nom}
                                            </button>
                                        </>
                                    }

                                    return <li key={fichier.id}>{content}</li>;
                                })}
                            </ul>

                        </>
                }
            </section>
        </>
    );
}

function getTemporalDateDebutReflexion(abonnement) {
    if (abonnement.date_fin) {
        // 6 mois avant la date de fin
        const temporalDateFin = Temporal.PlainDate.from(abonnement.date_fin);
        return temporalDateFin.subtract({months: 6 })
    } else {
        // 6 mois avant la date de reconduction
        const temporalDateReconduction = getTemporalFromDate(getDateReconduction(abonnement));
        return temporalDateReconduction.subtract({months: 6 })
    }
}

function getTemporalDateLimitePreavis(abonnement) {
    // 2 mois avant la date de reconduction
    const temporalDateReconduction = getTemporalFromDate(getDateReconduction(abonnement));
    return temporalDateReconduction.subtract({months: 2 })
}

function getDateReconduction(abonnement) {
    const date = new Date(abonnement.date_debut);

    const now = new Date();
    // date de reconduction équivaut à la date anniversaire de l'année en cours
    date.setFullYear(now.getFullYear());

    // si la date anniversaire de l'année en cours est dépassée, la date de reconduction
    // équivaut à la date anniversaire de l'année prochaine
    if (now > date) {
        date.setFullYear(now.getFullYear() + 1);
    }

    return date;
}

function getTemporalFromDate(date) {
    return Temporal.PlainDate.from({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
    });
}

function TimelineSvgCircle(props) {
    return (
        <svg
            className={`point ${props.className}`}
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="cible une date dans la frise chronologique de l'abonnement"
        >
            <circle r="5" cx="5" cy="5" />
        </svg>
    );
}

export default Abonnement;
