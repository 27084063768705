import React from "react";

export default function ConditionsGeneralesVente() {

    return (
        <section className="section-contenu-page page-statique">
            <h1 className="titre-page">Conditions générales de vente</h1>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 1 - Identification</h2>
                <address>
                    Syndicat Mixte Numérian<br/>
                    Siège : 2 Z.I. Rhône Vallée 07250 LE POUZIN<br/>
                    Président : M. Jérôme BERNARD<br/>
                    Siret : 824 924 52600034<br/>
                    Siret : 250 702 15600051<br/>
                    E-mail : <a href="mailto:contact@numerian.fr">contact@numerian.fr</a><br/>
                    Site internet : <a href="https://www.numerian.fr/">https://www.numerian.fr/</a>
                </address>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 2 - Objet</h2>
                <p>
                    Les présentes « Conditions Générales de Vente » (CGV) s’appliquent à tous les contrats conclus entre la Collectivité et Numérian.
                </p>
                <p>
                    En conséquence, toute adhésion à Numérian implique l’acceptation sans réserve par la collectivité aux présentes CGV.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 3 - Protection des données</h2>
                <p>
                    Dans le cadre de leurs relations contractuelles, les parties s’engagent à respecter la réglementation en vigueur applicable au traitement de données
                    à caractère personnel et, en particulier, le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 applicable depuis le 25 mai
                    2018 (ci-après, « le règlement européen sur la protection des données »).
                </p>
                <p>
                    Numérian s’engage à traiter les données uniquement pour la ou les seule(s) finalité(s) qui fait/font l’objet du contrat. Ainsi, il garantit la confidentialité
                    de vos données personnelles par les mesures de sécurité nécessaires. A ce titre, Numérian s’engage à ne pas communiquer la moindre information personnelle à
                    des tiers. De plus, les agents ont reçu la formation nécessaire en matière de protection des données à caractère personnel.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 4 - Prix des services, facturation et règlement</h2>
                <p>
                    Les prix des prestations proposés sont ceux mentionnés dans le devis ou contrats ; ils s’entendent hors taxes et sont payables en euros. La signature du devis
                    par le client vaut reconnaissance du prix de vente ou de la prestation. Les tarifs font l’objet d’une délibération en conseil de l’organe délibérant de Numérian
                    et sont consultables sur <a href="https://www.numerian.fr/">https://www.numerian.fr/</a>
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 5 - Commande</h2>
                <p>
                    Nos conditions générales de vente s’appliquent à toutes nos ventes ou/et prestations de services. En signant le document dénommé devis ou proposition financière,
                    le client accepte sans réserve nos conditions de vente. Les commandes de nos clients sont fermes après délai de rétractation de 14 jours ou sauf avis contraire de
                    notre part sous huitaine à compter de la réception du bon de commande.
                </p>
                <p>
                    Nos devis et bons de commande sont réputés valables pour une durée d’un mois (30 jours).
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 6 - Responsabilité des parties</h2>
                <p>
                    Numérian s’engage à fournir les prestations prévues contractuellement en échange du paiement des sommes dues au titre de la vente du matériel informatique ou de la
                    prestation de service.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 7 - Limitation des responsabilités</h2>
                <p>
                    Numérian ne saurait être tenu responsable en cas de mauvaise manipulation de la part des utilisateurs (suppression ou modification sur le serveur, désinstallation des
                    outils informatiques et de protection fournis, etc.).
                </p>
                <p>
                    Les cocontractants s’engagent également à ne pas entraver le travail des agents mis à disposition par Numérian.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 8 - Obligation d'information</h2>
                <p>
                    Le client s’engage à informer, par écrit Numérian de toute modification concernant sa situation (changement d’adresse, numéro de SIRET, RIB, etc.).
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 9 - Assistance</h2>
                <p>
                    Les services d’assistance téléphonique de Numérian sont destinés à fournir une aide en cas de difficultés rencontrées sur les logiciels mis à disposition ou les problèmes
                    avec les outils informatiques. Le cas échéant et selon les modalités contractuelles, un technicien pourra intervenir directement sur site.
                </p>
                <p>
                    Nos agents sont joignables du lundi au vendredi de 8h30 à 12h et de 13h30 à 17h30.
                </p>
                <p>
                    Numéro accueil : <a href="tel:+0475301313">04.75.30.13.13</a>
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 10 - Retard de paiement</h2>
                <p>
                    Le paiement doit être effectué dans un délai de trente (30) jours à compter de la réception de la facture.
                </p>
                <p>
                    En cas de non-paiement des factures relatives au contrat de vente ou de prestation de service, Numérian adressera une première relance amiable puis le cas échéant une relance
                    écrite. À défaut d’une réponse ou de paiement des factures, une lettre recommandée avec accusé de réception sera envoyée, valant mise en demeure de paiement, sous huit jours.
                </p>
                <p>
                    Tout incident et/ou retard de paiement à l’échéance entrainera suspension des services suite à la lettre de mise en demeure de règlement. Cette suspension cessera à la date de
                    règlement de la facture.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 11 - Litiges</h2>
                <p>
                    Pour tout litige, n’ayant pu être réglé à l’amiable, les parties pourront saisir le tribunal administratif du lieu de siège du syndicat, à Lyon.
                </p>
            </section>
            <section className="section-contact-cgv">
                Pour toute question relative aux présentes conditions générales, envoyez un e-mail à : <a href="mailto:contact@numerian.fr">contact@numerian.fr</a>
            </section>
        </section>
    );
}
