import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./header.css";
import { Link } from "react-router-dom";


export default function Header({ handleClick }) {
    return (
        <header>
            <Link
                id="header-logo"
                to="/">
                <img
                    src="/img/logo_N_bleu.svg"
                    alt="Retour accueil"
                />
            </Link>
            <div id="header-text">
                <h1
                    className="header-title">
                    Bienvenue sur MyNumérian&nbsp;!
                </h1>
                <p className="header-subtitle">
                    Un espace dédié à tous vos services Numérian
                </p>
            </div>
            <button
                id="hamburger-icon-wrapper"
                onClick={handleClick}>
                <FontAwesomeIcon
                    icon={solid("bars")}
                    size="2x"
                    id="hamburger-icon"
                />
            </button>
        </header>
    );
}
