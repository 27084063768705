import React from "react";
import "./forms.css";

export default function Input(props) {

    const handleChange = (event) => {
        if(typeof props.setValue === 'function') {
            props.setValue(event.target.value)
        }

        if(typeof props.onChange === 'function') {
            props.onChange(event)
        }
    }

    return (
        <input
            className={props.className}
            placeholder={props.placeholder}
            onChange={handleChange}
            value={props.value !== null ? props.value : undefined}
            type={props.type}
            name={props.name}
            id={props.id}
            disabled={props.disabled}
            required={props.required}
            pattern={props.pattern}
            title={props.title}
            inputMode={props.inputMode}
            minLength={props.minLength}
            aria-labelledby={props.ariaLabelledby}
            checked={props.checked}
            />
    );
}