import { useState } from "react";

const LOCAL_STORAGE_KEY = "token";

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (tokenString === null) {
            return null;
        }

        const userToken = JSON.parse(tokenString);
        return userToken?.token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        if (userToken === null) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            setToken(null);
            return;
        }

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userToken));
        return setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token,
    };
}
