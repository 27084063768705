import React from "react";

export default function ConditionsGeneralesUtilisation() {

    return (
        <section className="section-contenu-page page-statique">
            <h1 className="titre-page">Conditions générales d’utilisation du Portail</h1>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Editeur</h2>
                <address>
                    Syndicat Mixte Numérian (Numérian)<br/>
                    2 ZI Rhône Vallée Sud 07250 – LE POUZIN<br/>
                    Téléphone : <a href="tel:+0475301313">04 75 30 13 13</a><br/>
                    Directeur de la publication : Jean-Charles MANRIQUE
                </address>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Hébergement</h2>
                <address>
                    Numérian<br/>
                    2 ZI Rhône Vallée Sud 07250 – LE POUZIN<br/>
                    Téléphone : <a href="tel:+0475301313">04 75 30 13 13</a>
                </address>
                <p>
                    Les présentes Conditions Générales d’Utilisation (ci-après les “CGU”) régissent nos rapports avec vous, personne accédant au Portail MYNUMERIAN,
                    applicables durant votre utilisation du Portail et jusqu’à désactivation de votre compte. Si vous n’êtes pas d’accord avec les termes des CGU,
                    il vous est vivement recommandé de ne pas utiliser notre Portail et nos services.
                </p>
                <p>
                    Nous vous encourageons à consulter les Conditions Générales d’Utilisation et politique de protection des données personnelles avant votre première
                    utilisation de notre Portail et régulièrement lors de leurs mises à jour. Nous pouvons en effet être amenés à modifier les présentes CGU. Si des
                    modifications sont apportées, nous vous informerons par email ou via notre Portail pour vous permettre d’examiner les modifications avant qu’elles
                    ne prennent effet. Si vous continuez à utiliser notre Portail après la publication ou l’envoi d’un avis concernant les modifications apportées aux
                    présentes conditions, cela signifie que vous acceptez les mises à jour. Les CGU qui vous seront opposables seront celles en vigueur lors de votre
                    utilisation du Portail.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 1. Inscription au service</h2>
                <section className="sous-section">
                    <h3>1.1 Conditions d’inscription au Portail</h3>
                    <p>
                        Les fonctionnalités du Portail nécessitent d’être inscrit et d’obtenir un compte. Avant de pouvoir vous inscrire sur le Portail vous devez
                        avoir lu et accepté les présentes CGU et la Politique de protection des données personnelles.
                    </p>
                    <p>
                        Vous déclarez avoir la capacité d’accepter les présentes conditions générales d’utilisation, c’est-à-dire avoir plus de 16 ans et ne pas
                        faire l’objet d’une mesure de protection juridique des majeurs (mise sous sauvegarde de justice, sous tutelle ou sous curatelle).
                    </p>
                    <p>
                        Notre Portail ne prévoit aucunement l’inscription, la collecte ou le stockage de renseignement relatifs à toute personne âgée de 13 ans ou moins.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>1.2 Création de compte</h3>
                    <p>
                        Numérian crée votre compte à partir des informations collectées depuis son système de gestion de clientèle, dont certaines informations sont
                        à caractère personnel telles que votre nom, prénom et votre adresse email.
                        Pour activer votre compte, vous recevrez un mail contenant un lien unique qui vous permettra de créer votre mot de passe, puis de vous connecter à
                        notre Portail. Vous trouverez le descriptif du traitement de vos données dans notre politique de protection des données personnelles.
                    </p>
                    <p>
                        A l’occasion de la création de votre compte, vous vous engagez à fournir des informations personnelles vraies, exactes, complètes et à les mettre à
                        jour par l’intermédiaire de votre profil ou en en avertissant NUMERIAN, afin d’en garantir la pertinence et l’exactitude tout au long de votre relation
                        avec NUMERIAN.
                    </p>
                    <p>
                        Vous vous engagez à garder secret le mot de passe choisi lors de la création de votre compte et à ne le communiquer à personne. En cas de perte ou divulgation
                        de votre mot de passe, vous vous engagez à en informer sans délai NUMERIAN. Vous êtes seul responsable de l’utilisation faite de votre compte par un tiers, tant
                        que vous n’avez pas expressément notifié NUMERIAN de la perte, l’utilisation frauduleuse ou la divulgation de votre mot de passe à un tiers.
                    </p>
                    <p>
                        Vous vous engagez à ne pas créer ou utiliser, sous votre propre identité ou celle d’un tiers, d’autres comptes que celui initialement créé. Vous ne pouvez pas
                        autoriser des tiers à utiliser votre compte. Vous ne pouvez pas céder ou, quoi qu’il en soit, transférer votre compte à toute autre personne ou entité.
                    </p>
                    <p>
                        Lorsque vous choisissez votre mot de passe, vous ne devez pas utiliser de mot de passe simpliste (par exemple : 123456).
                    </p>
                    <p>
                        L’identifiant et le mot de passe seront strictement personnels et confidentiels et vous devrez les conserver et les utiliser de manière à en préserver la stricte confidentialité.
                    </p>
                    <p>
                        Le membre sera seul autorisé à accéder au service à l’aide de son identifiant et son mot de passe. Toute utilisation de le Portail au moyen de ses identifiants et mot de passe est
                        réputée avoir été faite par le membre lui-même. En cas d’utilisation par un tiers de ses identifiant et mot de passe, le membre devra en avertir immédiatement NUMERIAN en lui
                        adressant un email à l’adresse suivante : <a href="mailto:dpo@numerian.fr">dpo@numerian.fr</a>.
                    </p>
                    <p>
                        Le membre qui a perdu son mot de passe devra se connecter sur le Portail MYNUMERIAN et suivre la procédure en cliquant sur le lien 'mot de passe oublié'.
                    </p>
                    <p>
                        Le membre est responsable de l’utilisation du Portail et de toutes les actions réalisées au sein du Portail avec son identifiant et son mot de passe, sauf si l’utilisation
                        de son compte a été faite après sa désinscription, ou après notification à NUMERIAN d’une utilisation abusive de son compte.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 2. Informations fournies par vous</h2>
                <p>
                    Chaque personne garantit à NUMERIAN que les informations qu’elle fournit quant à son identité et ses coordonnées dans le cadre du service sont vraies, exactes, complètes et à jour.
                    Vous êtes seul responsable de la sincérité et de l’exactitude de ces informations. Vous vous engagez à mettre à jour régulièrement l’ensemble des informations afin de préserver leur
                    exactitude.
                </p>
                <p>
                    NUMERIAN ne pourra en aucun cas être tenue responsable des erreurs, omissions, imprécisions pouvant être relevées dans les informations que vous nous avez fournies, ni du préjudice
                    pouvant éventuellement en découler pour les autres utilisateurs ou pour des tiers.
                </p>
                <p>
                    Vous êtes responsable de toute l’activité qui se déroule sur votre compte, et vous acceptez de préserver à tout moment la sécurité et le secret de votre identifiant et de votre mot de
                    passe. Vous ne pouvez posséder qu’un seul compte.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 3. Propriété intellectuelle</h2>
                <section className="sous-section">
                    <h3>3.1. Propriété intellectuelle afférente au service, à notre Portail et aux éléments qui les composent</h3>
                    <p>
                        L’ensemble des éléments techniques, graphiques, textuels ou autres constituant le service et/ou notre Portail (textes, graphismes, logiciels, fichiers multimédias, photographies,
                        images, vidéos, sons, plans, charte graphique, technologie(s), codes sources, noms, marques, logos, visuels, bases de données, etc.) ainsi que notre Portail et le service eux-mêmes
                        sont la propriété exclusive de NUMERIAN.
                    </p>
                    <p>
                        L’utilisateur reconnaît qu’aucune propriété ne lui est transmise, et qu’aucun droit ou licence ne lui est accordé(e), en dehors d’un droit d’utiliser le service conformément aux
                        présentes pendant la durée du contrat, et des droits d’utilisation des cours qui lui sont concédés en vertu des licences Creative Commons visées à l’article 4 ci-après.
                    </p>
                    <p>
                        En conséquence, sauf autorisation expresse et préalable de NUMERIAN et/ou sauf licence Creative Commons l’y autorisant, l’utilisateur s’engage à ne pas :
                    </p>
                    <ul>
                        <li>
                            Reproduire, à des fins commerciales ou non, des documents présents au sein du service et/ou les éléments techniques, graphiques, textuels ou autres constituant le service et/ou notre Portail ;
                        </li>
                        <li>
                            Intégrer tout ou partie du contenu du service et/ou de notre Portail dans un site tiers, à des fins commerciales ou non ;
                        </li>
                        <li>
                            Utiliser un robot, notamment d’exploration (spider), une application de recherche ou de récupération de sites web ou tout autre moyen permettant d’extraire, réutiliser ou d’indexer
                            tout ou partie du contenu du service et/ou de notre Portail ;
                        </li>
                        <li>
                            Collecter les informations sur les utilisateurs pour leur envoyer des messages non sollicités et/ou les intégrer au sein d’un service de référencement ou équivalent, gratuit ou payant ;
                        </li>
                    </ul>
                    <p>
                        Toute utilisation non expressément autorisée d’éléments du service et/ou de notre Portail engage la responsabilité civile et/ou pénale de son auteur et sera susceptible d’entraîner des
                        poursuites judiciaires à son encontre.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>3.2. Propriété intellectuelle afférente aux contenus publiés par l’utilisateur au sein du service</h3>
                    <p>
                        <span className="texte-gras texte-gris">3.2.1. </span>En contrepartie de l’usage du service, les membres accordent à NUMERIAN une licence mondiale, non-exclusive, transférable et pouvant donner lieu
                        à l’octroi d’une sous-licence, conférant à NUMERIAN le droit de copier, stocker, corriger, adapter et diffuser l’ensemble des contenus (en ce compris, les contrats, les devis et toute autre
                        activité réalisée par les membres au sein du service) fournis par le membre au sein du service. Cette licence est concédée au fur et à mesure de la publication des éléments concernés, aux
                        seules fins du bon fonctionnement du service.
                    </p>
                    <p>
                        <span className="texte-gras texte-gris">3.2.2. </span>Les membres susmentionnés garantissent qu’ils disposent des droits de propriété intellectuelle et des droits de la personnalité (et en particulier,
                        le droit à l’image) nécessaires à la publication des contenus qu’ils publient au sein du service.
                    </p>
                    <p>
                        Ils garantissent également que les contenus qu’ils publient au sein du service ne contiennent rien qui soit contraire aux droits des tiers et aux lois en vigueur, et notamment aux dispositions
                        relatives à la diffamation, à l’injure, à la vie privée, au droit à l’image, à l’atteinte aux bonnes mœurs ou à la contrefaçon.
                    </p>
                    <p>
                        Vous garantissez ainsi NUMERIAN contre tout recours éventuel d’un tiers concernant la publication desdits contenus au sein du service.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 4. Fonctionnalités du service</h2>
                <section className="sous-section">
                    <h3>4.1. La Contrathèque</h3>
                    <p>
                        Le Portail MYNUMERIAN développée par NUMERIAN a pour objectif de fournir aux utilisateurs un système centralisé permettant de suivre et de gérer efficacement les contrats passés. En offrant
                        une Contrathèque numérique, le Portail vise à simplifier et à rationaliser la gestion des contrats, en facilitant leur accès, leur recherche, leur archivage et leur suivi tout au long de
                        leur cycle de vie. Elle permet également de garantir la conformité et la sécurité des informations contractuelles, offrant ainsi aux utilisateurs un outil performant pour optimiser leur
                        gestion contractuelle et minimiser les risques associés.
                    </p>
                    <p>
                        La Contrathèque numérique contient : les contrats de prestations de services choisis par la collectivité et mis en place par NUMERIAN, les documents afférents à ces prestations : devis,
                        documents de paramétrage, de formation etc.
                    </p>
                    <p>
                        Les contrats terminés sont également accessibles dans la Contrathèque.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>4.2. Les informations de la collectivité adhérente</h3>
                    <p>
                        L’utilisateur accède uniquement aux informations de la collectivité à laquelle il est rattaché. Ces informations sont collectées dans la base de gestion des contrats détenue
                        par NUMERIAN.
                    </p>
                    <p>
                        L'utilisateur peut modifier ses informations personnelles (nom, prénom, adresse mail et mot de passe) ainsi que celles de la collectivité à laquelle il est rattaché (Nom de la collectivité,
                        adresse mail, numéro de téléphone, adresse, nombre d'habitants / d'ETP), respectivement via les pages « mon compte » et « Informations de la collectivité ».
                    </p>
                    <p>
                        NUMERIAN s’engage à respecter sa politique de protection des données personnelles pour l’utilisation de ces données. A toutes fins, ces données permettent de tenir les fichiers adhérents
                        à jour et d’être en contact en temps réel avec la collectivité. Ceci permet un gain de temps et d’organisation pour les deux parties co-contractantes.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>4.3. La communication</h3>
                    <p>
                        MYNUMERIAN a vocation à être une interface entre NUMERIAN et ses collectivités adhérentes. Sur le portail sont accessibles les informations fournies par le service Communication de la
                        structure.
                    </p>
                    <p>
                        Ainsi, le Portail propose des alertes de mise à jour des outils et logiciels proposés par NUMERIAN, les dates des prochains Conseils Syndicaux, un accès direct à la Newsletter etc…
                    </p>
                </section>
                <section className="sous-section">
                    <h3>4.4. Les offres de service</h3>
                    <p>
                        NUMERIAN s’engage à proposer sur MYNUMERIAN les services de son catalogue avec les prix actualisés.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>4.5. Formulaire de contact</h3>
                    <p>
                        Les utilisateurs ont la faculté d’accéder au formulaire de contact afin de pouvoir communiquer avec les services de NUMERIAN sur une question liée à ces services.
                    </p>
                    <p>
                        L’utilisateur s’engage à ne pas diffuser de correspondance privée sur le formulaire de contact.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 5. Signalement – modération a posteriori</h2>
                <p>
                    Tout utilisateur a la possibilité de signaler à NUMERIAN tout message ou commentaire et plus généralement tout contenu publié au sein du service qui serait contraire
                    au contrat ou autrement illicite, et notamment outrageant, injurieux, diffamatoire, abusif, violent, obscène ou pornographique, ou comprenant une provocation à la
                    discrimination ou à la haine fondée sur la race, la religion, le sexe, ou autre, une provocation aux crimes et délits, ou une apologie de crime, ou de nature à porter
                    atteinte aux droit de propriété intellectuelle ou aux droits de la personnalité des tiers, ou encore de nature à altérer le fonctionnement du service et/ou de notre Portail,
                    de quelque manière que ce soit.
                </p>
                <div className="container-adresse">
                    <p>
                        Pour cela, vous devrez contacter NUMERIAN, soit par email à l’adresse : <a href="mailto:contact@numerian.fr">contact@numerian.fr</a>,
                        soit à l’aide du formulaire de contact accessible au sein du portail, soit par courrier postal à l’adresse :
                    </p>
                    <address> NUMERIAN, 2 ZI Rhône Vallée Sud 07250 – LE POUZIN </address>
                    <p>
                        et procéder comme suit :
                    </p>
                </div>
                <ul>
                    <li>
                        Décliner votre identité ;
                    </li>
                    <li>
                        Décrire le contenu litigieux de manière précise et détaillée ainsi que sa localisation sur notre Portail ;
                    </li>
                    <li>
                        Décrire les motifs de fait et droit pour lesquels le contenu litigieux doit être considéré comme manifestement illicite et, par suite, retiré de notre Portail ;
                    </li>
                    <li>
                        Adresser la copie de la correspondance que vous aurez préalablement envoyée à l’auteur de ce contenu pour en demander la modification ou le retrait et/ou la
                        justification que vous n’avez pas pu contacter cet auteur.
                    </li>
                </ul>
                <p>
                    Nous nous réservons le droit de supprimer tout contenu illicite ou non conforme au contrat qui aura été préalablement signalé. Tout signalement manifestement abusif
                    pourra être sanctionné par NUMERIAN.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 6. Protection des données personnelles</h2>
                <p>
                    Nous portons une attention particulière à la protection de vos données personnelles. Vous trouverez plus loin un chapitre dédié à cette question.
                </p>
                <p>
                    NUMERIAN invite expressément l’utilisateur à consulter sa politique de protection des données personnelles qui fait partie intégrante des présentes CGU.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 7. Obligations des utilisateurs et charte de bonne conduite</h2>
                <section className="sous-section">
                    <h3>7.1. Obligations des Utilisateurs</h3>
                    <p>
                        Dans le cadre de l’utilisation du Portail, nous vous demandons de vous engager à :
                    </p>
                    <ul>
                        <li>
                            Garantir l’exactitude, l’intégrité et la légalité des informations que vous fournissez sur le Portail quant à votre identité et vos coordonnées ;
                        </li>
                        <li>
                            Garantir le bon usage du Portail ;
                        </li>
                        <li>
                            Ne créer qu’un seul compte au sein du Portail ;
                        </li>
                        <li>
                            S’abstenir de saisir des informations et/ou messages, commentaires et autres contenus malveillants, dénigrants, diffamatoires, injurieux, obscènes,
                            pornographiques, violents, à caractère raciste, xénophobe, discriminatoire, volontairement trompeurs, illicites et/ou contraires à l’ordre public
                            ou aux bonnes mœurs ;
                        </li>
                        <li>
                            Respecter les droits des tiers, et notamment le droit de chacun au respect de sa vie privée, de son image et de ses autres droits de la personnalité,
                            ainsi que les droits de propriété intellectuelle (droit d’auteur, droits voisins, droit sur les bases de données, droit des marques, droit des brevets,
                            dessins ou modèles, secret de fabrique, etc.) ;
                        </li>
                        <li>
                            Ne pas usurper la qualité, l’attribut ou l’identifiant d’un autre utilisateur ou d’un tiers de nature à induire en erreur ou à créer une confusion quelconque
                            quant à son identité, à la provenance des informations, contenus (cours, messages, commentaires, etc.) qu’il diffuse ou transmet au sein du Portail ;
                        </li>
                        <li>
                            Ne pas altérer ou perturber l’intégrité du Portail et/ou des données qui y sont contenues ;
                        </li>
                        <li>
                            Ne pas tenter d’obtenir un accès non autorisé au Portail ou aux systèmes ou réseaux qui lui sont associés ou d’intercepter des données ;
                        </li>
                        <li>
                            Utiliser le Portail dans le respect des législations et réglementations nationales et/ou internationales applicables.
                        </li>
                    </ul>
                    <p>
                        En cas de manquement à l’une de ces obligations, nous nous réservons le droit de suspendre temporairement ou définitivement le compte de l’utilisateur.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 8. Responsabilité de NUMERIAN</h2>
                <section className="sous-section">
                    <h3>8.1. A l’égard des informations et/ou contenus publiés au sein du Portail par les Utilisateurs</h3>
                    <p>
                        Les informations et contenus sur MYNUMERIAN sont en lien direct avec les outils de gestion des contrats et de facturation détenus par NUMERIAN.
                    </p>
                    <p>
                        En notre qualité d’hébergeur de ces informations et/ou contenus mis en ligne pour l’utilisateur par l’intermédiaire de notre Portail, nous sommes
                        soumis au régime de responsabilité atténuée prévu aux articles 6.I.2 et suivants de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie
                        Numérique. Nous pourrons, dans ce cadre, supprimer les informations et/ou contenus manifestement illicites qui nous seront notifiés.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>8.2. En cas d’inexécution de nos obligations</h3>
                    <p>
                        NUMERIAN ne sera responsable que des dommages directs subis par les utilisateurs, dont il sera établi qu’ils résultent de l’inexécution nos obligations.
                    </p>
                    <p>
                        En revanche, nous ne saurons en aucun cas être tenus responsables :
                    </p>
                    <ul>
                        <li>
                            Des dommages qui résulteraient du fait de l’utilisateur, de difficultés inhérentes au fonctionnement du réseau Internet et plus généralement des réseaux de
                            télécommunication, quelle que soit leur nature, du fait d’un tiers ou d’un cas de force majeure ;
                        </li>
                        <li>
                            Des dommages indirects résultant de l’utilisation du Portail, ceux-ci étant définis de façon non limitative comme les pertes d’exploitation (chiffre d’affaires,
                            revenus ou bénéfices), les pertes d’opportunités, les préjudices d’image ou de réputation, préjudice commercial ou économique ;
                        </li>
                    </ul>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 9. Interruption du service pour maintenance ou amélioration</h2>
                <p>
                    Nous nous engageons à mettre tout en œuvre pour assurer le bon fonctionnement du Portail et son accessibilité mais nous ne sommes tenus qu’à une obligation de moyens concernant
                    la continuité de l’accès au service. Nous ne pouvons garantir la pérennité ou les performances du Portail.
                </p>
                <p>
                    En cas de mise à jour du Portail pour une évolution technique, celle-ci pourra être inaccessible temporairement.
                </p>
                <p>
                    L’accès au service pourra être interrompu pour des raisons notamment de maintenance, de mise à jour et en cas d’urgence.
                </p>
                <p>
                    Plus généralement, l’interruption du service quel que soit la cause, la durée ou la fréquence, n’engagera pas notre responsabilité et ne donnera droit à aucune indemnité à
                    l’utilisateur.
                </p>
                <p>
                    En conséquence, nous ne pouvons être tenu pour responsable de la perte d’argent, ou de réputation, ni des dommages spéciaux, indirects ou induits résultant de l’interruption du service.
                    De même, nous ne pourrons être tenus pour responsable des éventuelles dégradations de matériel, logiciel ou données (exemple : contamination virale) subies par l’utilisateur du fait de
                    son utilisation du service.
                </p>
                <p>
                    Pour éviter autant que possible des désagréments, vous devez vous assurer de la réalisation régulière de sauvegardes concernant vos informations, contenus et logiciels.
                </p>
                <p>
                    Vous reconnaissez utiliser le service, mais un “bug” de notre Portail peut vous faire perdre certaines de vos données, les altérer ou les exposer. Nous nous engageons cependant
                    à tout mettre en œuvre pour les restaurer dans la mesure du possible et à vous garantir un accès au Portail le plus rapidement.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 10. Inscription et droits sur le compte utilisateur</h2>
                <section className="sous-section">
                    <h3>10.1. Par un membre</h3>
                    <p>
                        Tous les membres de notre Portail disposent d’un droit d’accès, de modification ou de suppression de leur compte.
                    </p>
                    <p>
                        Les utilisateurs peuvent à tout moment modifier, obtenir un droit d’accès à leur compte personnel ou demander la résiliation du compte en nous contactant par email à
                        l’adresse <a href="mailto:contact@numerian.fr">contact@numerian.fr</a>. Votre demande de suppression de compte entraînera la suppression de vos données personnelles de
                        nos bases de données.
                    </p>
                </section>
                <section className="sous-section">
                    <h3>10.2. Par NUMERIAN</h3>
                    <p>
                        Lorsque nous remarquons qu’un utilisateur du Portail ne respecte pas les présentes CGU et les CGV, nous prendrons contact avec lui pour l’avertir qu’une sanction à son égard
                        peut être prise, notamment la suspension de son compte. La charte de bonne conduite énoncée dans ces CGU doit être respectée par chacun pour le bon fonctionnement du Portail
                        et la bonne entente entre les personnes.
                    </p>
                    <p>
                        Si aucune solution ne parvient à être trouvée et que les règles de bonne conduite ne sont pas respectées, nous serons dans l’obligation de désactiver votre compte pour une durée
                        temporaire ou définitive et nous prendrons contact avec vous au préalable par email.
                    </p>
                </section>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 11. Force majeure</h2>
                <p>
                    Aucune des parties ne sera responsable vis-à-vis de l’autre partie d’un retard d’exécution ou d’une inexécution en raison de survenance d’un événement en dehors du contrôle des parties
                    qui ne pouvait être raisonnablement prévu lors de l’acceptation des CGU et dont les effets ne peuvent pas être évités par des mesures appropriées.
                </p>
                <p>
                    Le cas de force majeure suspend les obligations de la partie concernée pendant le temps où jouera la force majeure si cet évènement est temporaire. Néanmoins, les parties s’efforceront
                    d’en minimiser dans toute la mesure du possible les conséquences. A défaut, si l’empêchement est définitif, les parties seront libérées de leurs obligations dans les conditions prévues
                    aux articles 1351 et 1351-1 du code civil.
                </p>
            </section>
            <section className="section-article">
                <h2 className="sous-titre-page-statique">Article 12. Résolution des différends</h2>
                <p>
                    Les CGU sont soumises à la loi française. Toutes difficultés relatives à la validité, l’application ou à l’interprétation des CGU seront soumises, à défaut d’accord amiable, au tribunal
                    administratif de Lyon, auquel les parties attribuent compétence territoriale, quel que soit le lieu d’exécution ou le domicile du défendeur. Cette attribution de compétence
                    s’applique également en cas de procédure en référé, de pluralité de défendeurs ou d’appel en garantie.
                </p>
            </section>
        </section>
    );
}
