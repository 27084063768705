import React, { useState, useContext } from "react";
import { useLoaderData, useNavigate} from "react-router-dom";
import useToken from "../../hooks/useToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Input from "../../components/forms/Input";
import PrimaryButton from "../../components/ui/PrimaryButton";
import "./moncompte.css";
import { CurrentUserContext } from "../../context/CurrentUserContext";
import { safeJsonFetch, FAILED, safeFetch } from "../../utils";
import LoadFailed from "../../components/ui/LoadFailed";

export async function formUserLoader(token) {
    let form = await safeJsonFetch(
        `${API_URL}/user/form`,
        token
    );

    return form;
}

export default function MonCompte() {

    const initialFormUserData = useLoaderData();
    const navigate = useNavigate();
    // Dans initialFormUserData, les names des fields ne sont pas préfixés
    // avec le name du formulaire. On a besoin du préfixe pour que symfony
    // traite bien la soumission du formulaire.
    const getInputName  = (name) => `${initialFormUserData.name}[${name}]`;

    const [state, setState] = useState({
        openForm: false,
        oldPasswordShown: false,
        newPasswordShown: false,
        confirmNewPasswordShown: false,
        fields:
            initialFormUserData === FAILED
                ? {}
                : Object.fromEntries(
                      Object.entries(initialFormUserData.fields).map((entry) => [
                          getInputName(entry[0]),
                          entry[1].data,
                      ])
                  ),
        errorSubmit: false,
        errors: [],
    });

    const { token } = useToken();
    const { currentUser, setCurrentUserAndPersist } = useContext(CurrentUserContext);

    const onInputChange = (event) => {
        const input = event.target;
		setState({
            ...state,
            fields: {
                ...state.fields,
                [input.name]: input.value,
            }
        });
	};

    const onFormSubmit = async e => {
        setState({
            ...state,
            openForm: false,
            errorSubmit: false,
            errors: [],
        });

        e.preventDefault();
        const formData = new FormData(e.target);
        try {
            const response = await safeFetch(
                `${API_URL}/user/update`,
                token,
                {
                    method: "POST",
                    mode: "cors",
                    body: formData,
                }
            );

            if (response === FAILED) {
                throw new Error();
            }

            const data = await response.json();
            if(data.success === false){
                setState({
                    ...state,
                    openForm: true,
                    errorSubmit: true,
                    errors: data.errors,
                });
                return;
            }

            const nouveauNomUser = formData.get(getInputName('nom'));
            if(currentUser.name !== nouveauNomUser) {
                setCurrentUserAndPersist({
                    ...currentUser,
                    name: nouveauNomUser,
                })
            }
        } catch(ex) {
            setState({
                ...state,
                openForm: true,
                errorSubmit: true,
            });
        }
    }

    const inputClassName = state.openForm ? "normal-input" : "display-only-input";

    return (
        <>
            <section className="section-contenu-page">
                <h1 className="titre-page">Mon compte</h1>
                {
                    initialFormUserData === FAILED
                    ? <LoadFailed/>
                    : (
                        <>
                            {
                                // Il y a eu une erreur, mais on a aucun messages (par exemple: perte de connexion)
                                // donc on affiche un message par défaut
                                state.errorSubmit && state.errors.length === 0
                                    ? <div className="error-msg error-msg-mon-compte">Problème de serveur, veuillez réessayer</div>
                                    : null
                            }
                            <section className="section-form-user-container">
                                <form className="formulaire-user" onSubmit={onFormSubmit}>
                                    <div className="item-container">
                                        <FontAwesomeIcon
                                            icon={solid("user")}
                                            size="sm"
                                            className="block-icon"
                                        />
                                        <div className="input-container">
                                            <label htmlFor={'nom'}>Nom prénom ou service</label>
                                            <Input
                                                className= {inputClassName}
                                                value={state.fields[getInputName('nom')]}
                                                type={initialFormUserData.fields.nom.type}
                                                name={getInputName('nom')}
                                                id={'nom'}
                                                disabled={state.openForm ? initialFormUserData.fields.nom.disabled : true}
                                                required={initialFormUserData.fields.nom.required}
                                                onChange={onInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="item-container">
                                        <FontAwesomeIcon
                                            icon={solid("at")}
                                            size="sm"
                                            className="block-icon"
                                        />
                                        <div className="input-container">
                                            <label htmlFor={'email'}>Adresse email</label>
                                            {
                                                state.errors
                                                    .filter(error => error.field === "email")
                                                    .map((error, i) => (
                                                        <div key={i} className="error-msg">{error.message}</div>
                                                    ))
                                            }
                                            <Input
                                                className= {inputClassName}
                                                value={state.fields[getInputName('email')]}
                                                type={initialFormUserData.fields.email.type}
                                                name={getInputName('email')}
                                                id={'email'}
                                                disabled={state.openForm ? initialFormUserData.fields.email.disabled : true}
                                                required={initialFormUserData.fields.email.required}
                                                onChange={onInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="item-container">
                                        <FontAwesomeIcon
                                            icon={solid("lock")}
                                            size="sm"
                                            className="block-icon"
                                        />
                                        {state.openForm === false ? (
                                            <div className="input-container">
                                                <label htmlFor={'password_old'}>Mot de passe</label>
                                                <Input
                                                    className= {inputClassName}
                                                    placeholder="***********"
                                                    type={initialFormUserData.fields.password_old.type}
                                                    name={getInputName('password_old')}
                                                    id={'password_old'}
                                                    disabled={state.openForm ? initialFormUserData.fields.password_old.disabled : true}
                                                    required={initialFormUserData.fields.password_old.required}
                                                />
                                            </div>
                                        ) : (
                                                <>
                                                    <div className="input-container-password">
                                                        <p className="instructions-mdp">Le mot de passe doit contenir au moins 16 caractères dont 1 majuscule, 1 minuscule, 1 chiffre</p>
                                                        <div className="input-container">
                                                            <label htmlFor={'password_old'}>Ancien mot de passe</label>
                                                            {
                                                                state.errors
                                                                    .filter(error => error.field === "password_old")
                                                                    .map((error, i) => (
                                                                        <div key={i} className="error-msg">{error.message}</div>
                                                                    ))
                                                            }
                                                            <div className="input-pwd-with-icon-container">
                                                                <Input
                                                                    className= {inputClassName}
                                                                    type={state.oldPasswordShown ?  "text" : initialFormUserData.fields.password_old.type}
                                                                    name={getInputName('password_old')}
                                                                    id={'password_old'}
                                                                    disabled={state.openForm ? initialFormUserData.fields.password_old.disabled : true}
                                                                    required={initialFormUserData.fields.password_old.required}
                                                                />
                                                                <button className="icon-password-hidden" title="Afficher le mot de passe" onClick={() => setState({
                                                                    ...state,
                                                                    oldPasswordShown: !state.oldPasswordShown
                                                                })}>
                                                                    <FontAwesomeIcon
                                                                        icon={state.oldPasswordShown ? solid("eye-slash") : solid("eye")}
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="input-container">
                                                            <label htmlFor={'password_new'}>Nouveau mot de passe</label>
                                                            {
                                                                state.errors
                                                                    .filter(error => error.field === "password_new")
                                                                    .map((error, i) => (
                                                                        <div key={i} className="error-msg">{error.message}</div>
                                                                    ))
                                                            }
                                                            <div className="input-pwd-with-icon-container">
                                                                <Input
                                                                    className= {inputClassName}
                                                                    type={state.newPasswordShown ?  "text" : initialFormUserData.fields.password_new.type}
                                                                    name={getInputName('password_new')}
                                                                    id={'password_new'}
                                                                    disabled={state.openForm ? initialFormUserData.fields.password_new.disabled : true}
                                                                    required={initialFormUserData.fields.password_new.required}
                                                                />
                                                                <button className="icon-password-hidden" title="Afficher le mot de passe" onClick={() => setState({
                                                                    ...state,
                                                                    newPasswordShown: !state.newPasswordShown
                                                                })}>
                                                                    <FontAwesomeIcon
                                                                        icon={state.newPasswordShown ? solid("eye-slash") : solid("eye")}
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="input-container">
                                                            <label htmlFor={'password_new_confirm'}>Confirmation</label>
                                                            {
                                                                state.errors
                                                                    .filter(error => error.field === "password_new_confirm")
                                                                    .map((error, i) => (
                                                                        <div key={i} className="error-msg">{error.message}</div>
                                                                    ))
                                                            }
                                                            <div className="input-pwd-with-icon-container">
                                                                <Input
                                                                    className= {inputClassName}
                                                                    type={state.confirmNewPasswordShown ?  "text" : initialFormUserData.fields.password_new_confirm.type}
                                                                    name={getInputName('password_new_confirm')}
                                                                    id={'password_new_confirm'}
                                                                    disabled={state.openForm ? initialFormUserData.fields.password_new_confirm.disabled : true}
                                                                    required={initialFormUserData.fields.password_new_confirm.required}
                                                                />
                                                                <button className="icon-password-hidden" title="Afficher le mot de passe" onClick={() => setState({
                                                                    ...state,
                                                                    confirmNewPasswordShown: !state.confirmNewPasswordShown
                                                                })}>
                                                                    <FontAwesomeIcon
                                                                        icon={state.confirmNewPasswordShown ? solid("eye-slash") : solid("eye")}
                                                                        size="sm"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    {state.openForm === true ? (
                                        <div className="bouton-action">
                                            <PrimaryButton>Valider</PrimaryButton>
                                            <button
                                                type="button"
                                                className="secondary-btn"
                                                // on utilise navigate afin de 'rafraichir la page' et avoir les dernières
                                                // données enregistrées lorsque l'on clique sur le bouton annuler
                                                onClick={() => navigate('/mon-compte')}>
                                                Annuler
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="secondary-btn bouton-action"
                                            onClick={() => setState({
                                                ...state,
                                                openForm:true
                                            })}>
                                            Modifier
                                        </button>
                                    )}
                                </form>
                                <img
                                    className="mon-compte-img"
                                    src="/img/femme-ordinateur.png"
                                    alt=""
                                />
                            </section>
                        </>
                    )
                }
            </section>
        </>
    );
}
