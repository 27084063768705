import React from "react";
import { Temporal } from '@js-temporal/polyfill';
import "./badge.css";

const TEMPORAL_NOW = Temporal.Now.plainDateTimeISO();

export default function BadgeRenouvellementAbonnement(props) {
    if (!props.abonnement.date_fin || props.abonnement.statut === "Terminé") {
        return null;
    }

    const nombreMoisJusqueFinContrat = TEMPORAL_NOW.until(
        Temporal.PlainDate.from(props.abonnement.date_fin),
        { smallestUnit: 'month' }
    ).months;

    if (nombreMoisJusqueFinContrat > 6) {
        return null;
    }

    return <span className="alerte-renouvellement">A renouveler</span>;
}
